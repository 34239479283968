:root {
    --font-size-h1: 6rem; /* 96px */
    --font-size-h2: 3.5rem; /* 56px */
    --font-size-h3: 3rem; /* 48px */
    --font-size-h4: 1.5rem; /* 24px */
    --font-size-h5: 1.25rem; /* 20px */
    --body-font: 'Arial', sans-serif;
    --health-card-font: 'Iter', sans-serif;
    --chatbot-font: 'Outfit', sans-serif;
    --primary-font: 'Geist', sans-serif;
    --html-color: #1e1e1e;
    --primary-color: #1480ee;
    --primary-color-rgb: 20, 128, 238;
    --primary-hover-color: #0f6bcc;
    --primary-background-color: #ecf5fe;
    --secondary-color: #757575;
    --disabled-color: #b2b2b2;
    --border-color: #d6d6d6;
    --background-color: #f5f5f5;
    --card-background: #ffffff;
}

@media (max-width: 768px) {
    :root {
        --font-size-h1: 4rem; /* 64px */
        --font-size-h2: 2.5rem; /* 40px */
        --font-size-h3: 2rem; /* 32px */
        --font-size-h4: 1.25rem; /* 20px */
        --font-size-h5: 1rem; /* 16px */
    }

    html {
        scroll-behavior: smooth;
        font-size: 16px;
        height: 100%;
        color: var(--html-color);
    }

    body {
        margin: 8px;
    }
}

html {
    scroll-behavior: smooth;
    font-size: 16px;
    height: 100%;
    color: var(--html-color);
}

body {
    background-color: #ffffff;
    font-family: var(--primary-font), var(--body-font);
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: var(--primary-font), var(--heading-font), var(--body-font);
    margin: 0;
    width: fit-content;
}

h1 {
    font-size: var(--font-size-h1);
}

h2 {
    font-size: var(--font-size-h2);
}

h3 {
    font-size: var(--font-size-h3);
}

h4 {
    font-size: var(--font-size-h4);
    color: var(--secondary-color);
    font-weight: normal;
}

h5 {
    font-size: var(--font-size-h4);
}

p {
    color: var(--secondary-color);
    margin: 0;
}

ul,
li {
    text-decoration: none;
    margin: 0;
}

a,
a:hover,
a:active,
a:visited,
a:focus {
    text-decoration: none;
    color: inherit;
}

a {
    color: #007bff;
    text-decoration: none;
    font-weight: normal;
}

section {
    margin: 3rem 0;
}
